import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOffersList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'image', sortable: false },
    { key: 'title_en', sortable: true },
    { key: 'title_ar', sortable: true },
    { key: 'expiration_date', sortable: true },
    { key: 'offer_value', sortable: true },
    { key: 'offer_type', sortable: true },
    { key: 'offer_code', sortable: true },
    { key: 'is_active', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const offerTypeFilter = ref(null)
  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const reFetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter,offerTypeFilter], () => {
    reFetchData()
  })

  const fetchData = (ctx, callback) => {
    store
      .dispatch('app-offer/fetchData', {
        searchKey: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        is_active: statusFilter.value,
        offer_type: offerTypeFilter.value,
      })
      .then(response => {
        const { offers, total } = response.data

        callback(offers)
        totalData.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching offers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchData,
    reFetchData,
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    statusFilter,
    offerTypeFilter,
  }
}
